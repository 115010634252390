import React from 'react'
import {DialogActions, DialogContent} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import styled from 'styled-components'
import {PrimaryButton, SecondaryButton} from '../../Button'
import DestinationCard from './DestinationCard'

const CustomDialogTitle = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 2.4rem;
  font-weight: 700;
  margin: 15px 0px 10px;
`

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.5rem 0;
`

const PrimaryText = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.textPrimary};
  line-height: 1.2;
`

const SecondaryText = styled.div`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.textSecondary};
  line-height: 1.2;
`

const EnhancedDestinationCard = styled.div`
  padding: 1.5rem;
  border: 2px solid
    ${(props) => (props.isSelected ? props.theme.colors.primary : '#E0E0E0')};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.backgroundSelected : '#fff'};
  transition: all 0.2s ease;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Increase touch target size for better tablet interaction */
  min-height: 90px;
  margin: 0.5rem 0;
`

const ConfirmAddressModal = ({
  handleClose,
  updateSelectedAddress,
  setFormSubmitted,
  destinations = [],
  getValues,
  handleSubmitPrescription,
  updatePrescription,
  orderId,
  setValue,
}) => {
  const values = getValues()
  const classes = useStyles()
  const [selectedDestination, setSelectedDestination] = React.useState(null)
  const [originalAddresses] = React.useState({
    shippingAddress: values.shippingAddress,
    customerAddress: values.customerAddress,
  })

  const saveNewPrescription = () => {
    const {prescriptions} = values
    const prescriptionWithNumericPrices = prescriptions.map((prescription) => {
      prescription.price = prescription.price.toString().replace(/\$/, '')
      return prescription
    })

    const selectedDestinationAddress = destinations.find(
      (dest) => dest.googlePlaceId === selectedDestination,
    )?.address.description

    const formValues = {
      ...values,
      prescriptions: prescriptionWithNumericPrices,
      customerAddress: selectedDestinationAddress,
      shippingAddress: selectedDestinationAddress,
    }

    orderId
      ? updatePrescription(formValues)
      : handleSubmitPrescription(formValues)
  }

  const handleDestinationSelect = (googlePlaceId) => {
    setSelectedDestination(googlePlaceId)
    const selectedAddress = destinations.find(
      (dest) => dest.googlePlaceId === googlePlaceId,
    )?.address.description

    if (selectedAddress) {
      setValue('shippingAddress', selectedAddress, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }

  const handleModalClose = () => {
    setSelectedDestination(null)
    // Restore original addresses
    setValue('shippingAddress', originalAddresses.shippingAddress)
    setValue('customerAddress', originalAddresses.customerAddress)
    handleClose()
  }

  return (
    <div id="confirmation-dialog" className={classes.modal}>
      <CustomDialogTitle>Confirm Address</CustomDialogTitle>
      <DialogContent className={classes.destinationsContainer}>
        {destinations.map(
          ({googlePlaceId, address, primaryText, secondaryText}) => (
            <EnhancedDestinationCard
              key={googlePlaceId}
              isSelected={selectedDestination === googlePlaceId}
              onClick={() => handleDestinationSelect(googlePlaceId)}>
              <AddressContainer>
                <PrimaryText>
                  {primaryText ||
                    (() => {
                      const parts = address.description.split(',')
                      const postalCodeMatch = address.description.match(
                        /[A-Z]\d[A-Z] \d[A-Z]\d/,
                      )
                      return postalCodeMatch
                        ? `${parts[0]} (${postalCodeMatch[0]})`
                        : parts[0]
                    })()}
                </PrimaryText>
                <SecondaryText>
                  {secondaryText ||
                    address.description.split(',').slice(1).join(',')}
                </SecondaryText>
              </AddressContainer>
            </EnhancedDestinationCard>
          ),
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton
          id="go-back-button"
          className={classes.closeButton}
          onClick={handleModalClose}>
          Go Back
        </SecondaryButton>
        <PrimaryButton
          id="confirm-button"
          type="button"
          className={classes.successButton}
          disabled={!selectedDestination}
          onClick={async () => {
            if (selectedDestination) {
              try {
                const selectedAddress = destinations.find(
                  (dest) => dest.googlePlaceId === selectedDestination,
                )?.address.description

                if (selectedAddress) {
                  setValue('shippingAddress', selectedAddress)
                  setValue('customerAddress', selectedAddress)

                  updateSelectedAddress(selectedDestination)
                  setFormSubmitted(true)
                  saveNewPrescription()
                  handleClose()
                }
              } catch (error) {
                console.error(
                  'Error during address selection and form submission:',
                  error,
                )
              }
            }
          }}>
          Confirm
        </PrimaryButton>
      </DialogActions>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    height: '75vh',
    maxHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '1rem 1rem 1.5rem',
  },
  successButton: {
    flexGrow: 2,
    width: '23.7rem',
  },
  closeButton: {
    flexGrow: 1,
    width: '16rem',
    marginRight: '2rem',
    padding: '15px 0',
    boxSizing: 'border-box',
    borderRadius: '5px',
    justifyContent: 'center',
  },
  destinationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '1rem',
    overflowY: 'auto',
  },
}))

export default ConfirmAddressModal
