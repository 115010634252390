import React, {useRef} from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import {Controller} from 'react-hook-form'
import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'

import IconButton from '@material-ui/core/IconButton'

import TextField from '../../components/Textfield'
import Checkbox from '../../components/Checkbox'
import {Modal} from '../../components/Modal'
import Error from '../../components/Error'

import {DeletePrescriptionModal} from '../../components/Modal'
import {DeleteIcon} from '../../components/Icons'
import {barcodeParse} from '../../utils/common'

const Row = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  & > *:not(:last-child) {
    margin-right: 2rem;
  }
`

function NumberFormatCustom(props) {
  const {inputRef, ...other} = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      prefix={'$'}
      decimalScale={2}
      allowNegative={false}
    />
  )
}

const PrescriptionForm = ({
  position,
  autoFocus,
  errors,
  control,
  setValue,
  prescription,
  removeUnsavedPrescriptions,
  insert,
  remove,
  getValues,
  addNewPrescription,
  onPriceChange,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const barcodeInputRef = useRef()
  const priceInputRef = useRef()

  const handleModalClose = () => {
    setModalOpen(false)
  }
  const confirmDeleteIntent = () => {
    setModalOpen(true)
  }

  const handleBarcodeChange = (e, updateState) => {
    const inputValue = e.target.value
    const endOfBarcodeChar = 33
    const {prescriptions} = getValues()

    if (inputValue.charCodeAt(inputValue.length - 1) === endOfBarcodeChar) {
      const parsedPrice = barcodeParse(inputValue)
      const updateInputName = `prescriptions[${position}].price`
      setValue(updateInputName, parsedPrice)
      const lastItemPosition = prescriptions.length - 1
      position === lastItemPosition && addNewPrescription()
    } else {
      updateState(e)
    }
  }

  const handlePriceChange = (e, updateState) => {
    onPriceChange()
    updateState(e)
  }

  const deletePrescription = (prescription, position) => {
    const deleteSavedPrescription = async () => {
      let newPrescription = cloneDeep(prescription)
      newPrescription.isDeleted = true
      await remove(position)
      insert(position, newPrescription)
    }

    prescription.orderDetailId
      ? deleteSavedPrescription()
      : removeUnsavedPrescriptions(position)

    onPriceChange()
  }
  function isArrayFieldError(errors, path) {
    return !!get(errors, path, false)
  }

  function getArrayFieldErrorMessage(errors, path) {
    return get(errors, `${path}[message]`, '')
  }

  return (
    <>
      <Row id={`prescription-${position}`}>
        <Controller
          name={`prescriptions[${position}].barCodeNumber`}
          id={`prescriptions[${position}].barCodeNumber`}
          render={({onChange, onBlur, value}) => (
            <TextField
              id={`prescriptions-${position}-barCodeNumber-input`}
              type="text"
              label="Barcode Number"
              inputRef={barcodeInputRef}
              onChange={(e) => handleBarcodeChange(e, onChange)}
              error={isArrayFieldError(
                errors,
                `prescriptions[${position}].barCodeNumber`,
              )}
              helperText={getArrayFieldErrorMessage(
                errors,
                `prescriptions[${position}].barCodeNumber`,
              )}
              FormHelperTextProps={{
                component: (props) => (
                  <Error
                    name={`prescriptions[${position}].barCodeNumber`}
                    {...props}
                  />
                ),
              }}
              value={value}
              autoFocus={autoFocus}
            />
          )}
          onFocus={() => {
            barcodeInputRef.current.focus()
          }}
          control={control}
          defaultValue={prescription.barCodeNumber}
        />
        <Controller
          render={({onChange, onBlur, value}) => (
            <TextField
              id={`prescriptions-${position}-price-input`}
              type="decimal"
              label="Price"
              inputRef={priceInputRef}
              onChange={(e) => handlePriceChange(e, onChange)}
              error={isArrayFieldError(
                errors,
                `prescriptions[${position}].price`,
              )}
              helperText={getArrayFieldErrorMessage(
                errors,
                `prescriptions[${position}].price`,
              )}
              InputProps={{inputComponent: NumberFormatCustom}}
              FormHelperTextProps={{
                component: (props) => (
                  <Error name={`prescriptions[${position}].price`} {...props} />
                ),
              }}
              value={value}
            />
          )}
          defaultValue={prescription.price}
          name={`prescriptions[${position}].price`}
          id={`prescriptions-${position}-price-input`}
          control={control}
          onFocus={() => {
            priceInputRef.current.focus()
          }}
        />
        <Row id="testRow" style={{alignItems: 'center', width: 'auto'}}>
          <Controller
            name={`prescriptions[${position}].refrigerated`}
            control={control}
            defaultValue={prescription.refrigerated}
            render={({onChange, value}) => (
              <Checkbox
                id={`prescription-${position}-checkbox`}
                onChange={(e) => onChange(e.target.checked)}
                defaultChecked={prescription.refrigerated}
                containerStyles={{flexDirection: 'column-reverse'}}
                checkboxStyles={{margin: '15px 0px'}}
                label="Refrigerate"
                value={value}
              />
            )}
          />
          <Controller
            name={`prescriptions[${position}].isNarcotics`}
            control={control}
            
            defaultValue={prescription.isNarcotics}
            render={({onChange, value}) => (
              <Checkbox
                id={`prescription-${position}-checkbox`}
                onChange={(e) => onChange(e.target.checked)}
                defaultChecked={prescription.isNarcotics}
                containerStyles={{flexDirection: 'column-reverse'}}
                checkboxStyles={{margin: '15px 0px'}}
                label="Narcotic / CS"
                value={value}
              />
            )}
          />
          {/* // The next two component is only being used to track the controlled value of
          the input from the form. It is not meant to be interactive, removing it from the DOM
          will cause the two fields to be overwritten in the form state*/}
          <Controller
            as={<input />}
            name={`prescriptions[${position}].isDeleted`}
            defaultValue={prescription.isDeleted}
            control={control}
            style={{display: 'none'}}
          />
          <Controller
            as={<input />}
            name={`prescriptions[${position}].orderDetailId`}
            control={control}
            style={{display: 'none'}}
            defaultValue={prescription.orderDetailId}
          />
        </Row>
        <IconButton
          style={{width: '40px', height: '40px', alignSelf: 'center'}}
          id={`delete-prescription-${position}-button`}
          onClick={confirmDeleteIntent}>
          <DeleteIcon />
        </IconButton>
        <Modal isOpen={modalOpen} handleClose={handleModalClose}>
          <DeletePrescriptionModal
            deleteNewPrescription={() =>
              deletePrescription(prescription, position)
            }
            handleClose={handleModalClose}
          />
        </Modal>
      </Row>
    </>
  )
}

export default PrescriptionForm
