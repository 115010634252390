import React, {useState} from 'react'
import styled from 'styled-components'
import TextField from '../../components/Textfield'
import {PrimaryButton} from '../../components/Button'
import {formatToE164} from '../../utils/common'
import InputMask from 'react-input-mask'

export const CreateDriverForm = ({setShowDriverForm, addDriver}) => {
  const [formData, setFormData] = useState({
    email_address: '',
    family_name: '',
    given_name: '',
    phone_number: '',
    status: 'ACTIVE',
    driverType: '',
    vehicle_description: '',
    vehicle_color: '',
    license_plate: '',
  })

  const handleChange = (e) => {
    const {name, value} = e.target

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDriverFormSubmit = (e) => {
    e.preventDefault()

    const formattedPhone = formatToE164(formData.phone_number)

    if (!formattedPhone) {
      alert('Please enter a valid phone number')
      return
    }

    const updatedFormData = {
      ...formData,
      phone_number: formattedPhone,
    }

    addDriver(updatedFormData)
    setShowDriverForm(false)
  }

  return (
    <>
      <BackLink onClick={() => setShowDriverForm(false)}>{'< Back'}</BackLink>
      <Form onSubmit={handleDriverFormSubmit}>
        <TextFieldLeft>
          <TextField
            type="text"
            label="Given Name"
            name="given_name"
            required
            onChange={handleChange}
            value={formData.given_name}
          />
        </TextFieldLeft>

        <TextFieldRight>
          <TextField
            type="text"
            label="Family Name"
            name="family_name"
            required
            onChange={handleChange}
            value={formData.family_name}
          />
        </TextFieldRight>

        <div>
          <StyledLabel>Phone Number</StyledLabel>
          <StyledInput
            as={InputMask}
            mask="(999) 999-9999"
            maskChar="_"
            type="tel"
            name="phone_number"
            required
            onChange={handleChange}
            value={formData.phone_number}
          />
        </div>

        <TextField
          type="email"
          label="Email Address"
          name="email_address"
          required
          onChange={handleChange}
          value={formData.email_address}
        />

        <StyledLabel>Driver Type</StyledLabel>
        <Select
          name="driverType"
          required
          value={formData.driverType}
          onChange={handleChange}>
          <option value="">Select Driver Type</option>
          <option value="Store Driver">Store Driver</option>
          <option value="SR Driver">Script-Runner Driver</option>
        </Select>

        <TextField
          type="text"
          label="Vehicle Description"
          name="vehicle_description"
          onChange={handleChange}
          value={formData.vehicle_description}
        />

        <TextField
          type="text"
          label="Vehicle Color"
          name="vehicle_color"
          onChange={handleChange}
          value={formData.vehicle_color}
        />

        <TextField
          type="text"
          label="License Plate"
          name="license_plate"
          onChange={handleChange}
          value={formData.license_plate}
        />

        <PrimaryButton type="submit" style={{marginRight: '16px'}}>
          Add Driver
        </PrimaryButton>
      </Form>
    </>
  )
}

const Form = styled.form`
  width: 100%;
  padding: 24px;

  & > div {
    margin-bottom: 24px;
  }
`

const TextFieldLeft = styled.div`
  display: inline-block;
  width: 45%;
`

const TextFieldRight = styled.div`
  display: inline-block;
  width: 45%;
  float: right;
  margin-bottom: 24px;
`

const StyledLabel = styled.label`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.label};
  line-height: 2rem;
  display: block;
`

const Select = styled.select`
  padding: 18.5px 14px;
  width: 100%;
  border: 1px solid #e0e0e0;
  color: ${({theme}) => theme.colors.label};
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 24px;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`

const BackLink = styled.p`
  font-weight: bold;
  color: ${({theme}) => theme.colors.primary};
  cursor: pointer;
  margin-bottom: 24px;
`

const StyledInput = styled.input`
  padding: 18.5px 14px;
  width: 100%;
  border: 1px solid #e0e0e0;
  color: ${({theme}) => theme.colors.label};
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.1);
  }
`
