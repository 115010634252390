import {put, takeEvery, select, throttle} from 'redux-saga/effects'

import {
  GET_ORDER,
  ADD_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  START_LOADING,
  STOP_LOADING,
  SEARCH_ORDERS,
  SEARCH_ORDERS_CLEAR,
} from '../actions/types'
import {
  getOrderSuccess,
  addOrderSuccess,
  updateOrderSuccess,
  searchOrdersSuccess,
  showError,
  clearPaymentMethod,
  clearOrders,
  noMoreOrders,
} from '../../redux/actions'

import {request} from '../../utils/api'
import {getToken, getStripePaymentMethodId} from '../utils'
import {API_ORDERS_ENDPOINT, API_THROTTLE_TIME_MS} from '../../constants'
import {ORDERS_FETCH_LIMIT} from '../../utils/constants'

export function* getOrder({payload: {orderId}}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_ORDERS_ENDPOINT}/${orderId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const orderData = yield request(reqOpt)
    yield put(getOrderSuccess(orderData))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* addOrder({
  payload: {
    order,
    deliveryId,
    history,
    successCb = () => {},
    isBlankOrder = false,
  },
}) {
  const token = yield select(getToken)
  const stripePaymentMethodId = yield select(getStripePaymentMethodId)
  const body = {
    deliveryId,
    stripePaymentMethodId:
      order.paymentMethod === 'SR_CARD' ? stripePaymentMethodId : null,
    customerName: order.fullName,
    customerPhone: order.phoneNumber,
    customerAddress: order.shippingAddress,
    orderNote: order.deliveryNote,
    customerCreditCard: order.creditCard,
    detail: order.prescriptions
      ?.map(({barCodeNumber, price, refrigerated, isDeleted, narcotics}) => ({
        barcode: barCodeNumber,
        price,
        isRefrigerated: refrigerated,
        isNarcotics: narcotics,
        isDeleted,
      }))
      .filter(({isDeleted}) => isDeleted !== true),
  }
  const reqOpt = {
    method: 'POST',
    url: `${API_ORDERS_ENDPOINT}`,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const orderData = yield request(reqOpt)
    yield put(addOrderSuccess(orderData))
    yield put(clearPaymentMethod())
    isBlankOrder
      ? history.push(
          `/dashboard/delivery/${deliveryId}/add-new-prescription/${orderData.orderId}`,
        )
      : successCb()
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* updateOrder({
  payload: {
    orderId,
    updatedOrder,
    history,
    deliveryId,
    successCb = () => {},
    placeId = '',
    destinationId = '',
    errorCb,
  },
}) {
  const {
    prescriptions,
    paymentMethod,
    fullName,
    phoneNumber,
    shippingAddress,
    deliveryNote,
    creditCard,
  } = updatedOrder

  const token = yield select(getToken)
  const stripePaymentMethodId = yield select(getStripePaymentMethodId)
  const body = {
    detail: prescriptions.map(
      ({
        barCodeNumber,
        price,
        refrigerated,
        orderDetailId,
        isDeleted,
        isNarcotics,
      }) => ({
        barcode: barCodeNumber,
        price: price.length > 0 ? price : '0',
        isRefrigerated: refrigerated,
        isNarcotics,
        orderDetailId,
        isDeleted,
      }),
    ),
    stripePaymentMethodId:
      paymentMethod === 'SR_CARD' ? stripePaymentMethodId : null,
    customerName: fullName,
    customerPhone: phoneNumber,
    customerAddress: shippingAddress,
    placeId,
    destinationId,
    orderNote: deliveryNote,
    customerCreditCard: creditCard === 'NONE' ? null : creditCard,
  }

  const reqOpt = {
    method: 'PUT',
    url: `${API_ORDERS_ENDPOINT}/${orderId}`,
    token,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const orderData = yield request(reqOpt)
    yield put(updateOrderSuccess(orderData))
    yield put(clearPaymentMethod())
    successCb()
    history.push(`/dashboard/delivery/${deliveryId}`)
    yield put({type: STOP_LOADING})
  } catch (error) {
    if (errorCb) {
      console.log('errorCb')
      errorCb()
    }
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* deleteOrder({payload: {orderId}}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'DELETE',
    url: `${API_ORDERS_ENDPOINT}/${orderId.orderId || orderId}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  try {
    yield put({type: START_LOADING})
    yield request(reqOpt)
    yield put({type: STOP_LOADING})
    orderId.callback && orderId.callback()
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* searchOrders({payload: {searchInput, limit, offset}}) {
  const token = yield select(getToken)
  const reqOpt = {
    method: 'GET',
    url: `${API_ORDERS_ENDPOINT}-search?searchInput=${searchInput}&limit=${limit}&offset=${offset}`,
    token,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    yield put({type: START_LOADING})
    const responseOrders = yield request(reqOpt)
    yield put(searchOrdersSuccess(responseOrders.orders))
    if (responseOrders.orderCount < ORDERS_FETCH_LIMIT) {
      yield put(noMoreOrders())
    }
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* clearSearchedOrders() {
  try {
    yield put(clearOrders())
  } catch (error) {
    yield put(showError(error))
  }
}

export function* watchOrders() {
  yield throttle(API_THROTTLE_TIME_MS, GET_ORDER, getOrder)
  yield takeEvery(ADD_ORDER, addOrder)
  yield takeEvery(UPDATE_ORDER, updateOrder)
  yield takeEvery(DELETE_ORDER, deleteOrder)
  yield throttle(API_THROTTLE_TIME_MS, SEARCH_ORDERS, searchOrders)
  yield takeEvery(SEARCH_ORDERS_CLEAR, clearSearchedOrders)
}

export default watchOrders
