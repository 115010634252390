/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup'
import RadioGroup from '@material-ui/core/RadioGroup'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import React, {useEffect, useRef, useState} from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {connect} from 'react-redux'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {addMethod, array, object, string} from 'yup'
import BackButton from '../../components/BackButton'
import {
  CancelButton,
  PrimaryButton as ProceedButton,
  PrimaryButton as ScriptRunnerCardButton,
  SecondaryButton as AddPrescriptionButton,
} from '../../components/Button'
import Divider from '../../components/FormDivider'
import Loading from '../../components/LoadingOrange'
import {
  CancelPrescriptionDraftModal,
  CancelPrescriptionModal,
  Modal,
  ScriptRunnerCardLoadingModal,
  ScriptRunnerCardSuccessModal,
  ConfirmAddressModal,
} from '../../components/Modal'
import Radio from '../../components/Radio'
import {
  addOrder,
  clearPaymentMethod,
  deleteOrder,
  getLatestTransaction,
  getOrder,
  getPayment,
  paymentMethodSuccess,
  resetLatestTransactionSuccess,
  startLoading,
  stopLoading,
  updateDeliveryData,
  updateOrder,
  updatePurchaseModalFlag,
  validateAddress,
  clearAddresses,
} from '../../redux/actions'
import PrescriptionForm from '../PrescriptionForm'
import ShippingForm from '../ShippingForm'
import {
  BarcodeList,
  CardPaymentReg,
  DateText,
  Form,
  PaidAmountInput,
  PaymentDescription,
  Row,
  TopContainer,
} from './styles'
import {formatDate} from '../../utils/dates'
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginBottom: '1.2rem',
  },
  saveButton: {
    width: '21.6rem',
    margin: '15px 0',
  },
  topContainer: {
    textAlign: 'center',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  button: {
    width: '14rem',
    margin: '3rem auto',
  },
}))

const getOrderFromDelivery = (delivery, orderId) =>
  get(delivery, 'orders', []).reduce(
    (acc, order) => (order.orderId === parseInt(orderId) ? order : acc),
    {},
  )

addMethod(array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (x) => get(x, path)
    const set = [...new Set(list.map(mapper))]
    const isUnique = list.length === set.length
    if (isUnique) {
      return true
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i])
    return this.createError({path: `${this.path}[${idx}][${path}]`})
  })
})
addMethod(string, 'isValidCanadianPhone', function (message) {
  return this.test('isValidCanadianPhone', message, function (value) {
    if (!value) return false
    try {
      const cleanNumber = value.replace(/\D/g, '')
      const phoneNumber = phoneUtil.parse(cleanNumber, 'CA')
      return phoneUtil.isValidNumberForRegion(phoneNumber, 'CA')
    } catch (error) {
      return false
    }
  })
})
const prescriptionValidationSchema = object().shape({
  barCodeNumber: string().required('Barcode required'),
  price: string(),
})

const newPrescriptionValidationSchema = object().shape({
  fullName: string().required('Required'),
  shippingAddress: string()
    .required('Required')
    .min(5, 'Address value is too short')
    .max(200, 'Address value is too long'),
  phoneNumber: string()
    .required('Required')
    .isValidCanadianPhone('Please enter a valid Canadian phone number'),
  prescriptions: array()
    .of(prescriptionValidationSchema)
    .unique('Barcode is duplicated', 'barCodeNumber'),
  paymentMethod: string().required('Required'),
  cardHolderName: string().when('paymentMethod', {
    is: (val) => val === 'SR_CARD',
    then: string().required('Required'),
  }),
})

const AddNewPrescription = ({
  clearPayment,
  getPayment,
  paymentDetails,
  addOrder,
  updateOrder,
  getLatestTransaction,
  resetLatestTransactionSuccess,
  latestTransaction,
  newDeliveryData,
  selectedDelivery,
  loading,
  latestTransactionSuccess,
  updateDeliveryData,
  deleteOrder,
  updatePurchaseModalFlag,
  validateAddress,
  destinations,
  addressLoading,
  clearAddresses,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const {deliveryId, orderId} = useParams()
  const formRef = useRef()
  const [autofocusIndex, setAutofocusIndex] = useState(-1)
  const TRANSACTION_TYPES = {CHARGE: 'CHARGE', REFUND: 'REFUND'}
  const [latestTransactionType, setLatestTransactionType] = useState('')
  const [emptyOrderId, setEmptyOrderId] = useState(false)
  const [fetchedTransaction, setFetchedTransaction] = useState({})
  const [shippingFormFullName, setShippingFormFullName] = useState('')
  const [shippingFormPhoneNumber, setShippingFormPhoneNumber] = useState('')
  const [shippingFormAddress, setShippingFormAddress] = useState('')
  const [shippingFormDeliveryNote, setShippingFormDeliveryNote] = useState('')
  const [shippingFormCreditCard, setShippingFormCreditCard] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [selectedPlaceId, setSelectedPlaceId] = useState('')
  const [onfleetDestinationId, setOnfleetDestinationId] = useState('')
  const [hasValidationRun, setHasValidationRun] = useState(false)

  const isEditableOrder = location?.state?.isEditable ?? false
  const order = getOrderFromDelivery(selectedDelivery, orderId)
  // PAYMENT SCENARIOS
  const FETCHED_TRANSACTIONS = !isEmpty(fetchedTransaction)
  const ORDERED_WITH_SR = order?.paymentType === 'SR_CARD'
  const SR_TOUCHED =
    FETCHED_TRANSACTIONS && fetchedTransaction?.orderAmount !== ''
  const FETCHED_AMOUNT_ZERO = fetchedTransaction?.orderAmount === '$0.00'

  const scannedPrescription = {
    barCodeNumber: '',
    price: get(newDeliveryData, 'price', ''),
    refrigerated: false,
    isNarcotics: false,
    isDeleted: false,
  }

  const {register, handleSubmit, errors, control, reset, setValue, getValues} =
    useForm({
      defaultValues: {
        prescriptions: get(newDeliveryData, 'prescriptions', [
          scannedPrescription,
        ]),
        paymentMethod: 'NONE',
        fullName: get(newDeliveryData, 'customerName', ''),
        phoneNumber: get(newDeliveryData, 'customerPhone', ''),
        shippingAddress: get(newDeliveryData, 'customerAddress', ''),
        deliveryNote: get(newDeliveryData, 'deliveryNote', ''),
        creditCard: get(newDeliveryData, 'customerCreditCard', ''),
        cardHolderName: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
      },
      resolver: yupResolver(newPrescriptionValidationSchema),
    })
  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
    isPurchaseModal: false,
  })

  const {fields, append, remove, insert} = useFieldArray({
    control,
    name: 'prescriptions',
  })

  useEffect(() => {
    if (!hasValidationRun) return

    if (!Array.isArray(destinations)) return

    if (addressLoading == false) {
      if (destinations.length === 0) {
        setHasValidationRun(false)
        return
      }

      if (destinations.length === 1) {
        setSelectedPlaceId(destinations[0].googlePlaceId)
        if (destinations[0].onfleetDestinationId) {
          setOnfleetDestinationId(destinations[0].onfleetDestinationId)
        }

        const formValues = getValues()
        const updatedForm = {
          ...formValues,
          shippingAddress: destinations[0].address.description,
        }

        updatePrescription(updatedForm, () => {
          console.log('Order updated successfully')
        })
      } else if (destinations.length > 1) {
        handleModalOpen({modalContent: 'ConfirmAddressModal'})
      }

      setHasValidationRun(false)
    }
  }, [destinations, hasValidationRun, addressLoading])

  useEffect(() => {
    setShippingFormFullName(get(newDeliveryData, 'customerName', ''))
    setShippingFormPhoneNumber(get(newDeliveryData, 'customerPhone', ''))
    setShippingFormAddress(get(newDeliveryData, 'customerAddress', ''))
    setShippingFormDeliveryNote(get(newDeliveryData, 'deliveryNote', ''))
    setShippingFormCreditCard(get(newDeliveryData, 'customerCreditCard', ''))
    setFetchedTransaction(get(newDeliveryData, 'fetchedTransaction', ''))
  }, [])

  useEffect(() => {
    var pathname = window.location.pathname.split('/')
    var lastURLparam = pathname[pathname.length - 1]

    if (lastURLparam === 'add-new-prescription') {
      addOrder({
        order: {},
        deliveryId,
        history,
        successCb: () => {},
        isBlankOrder: true,
      })
    } else {
      setEmptyOrderId(lastURLparam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue('paymentMethod', 'NONE')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (orderId) {
      const {
        customerName,
        customerPhone,
        customerAddress,
        orderNote,
        customerCreditCard,
        detail,
      } = getOrderFromDelivery(selectedDelivery, orderId)

      const updatedForm = {
        prescriptions:
          newDeliveryData?.prescriptions?.length > 0
            ? newDeliveryData?.prescriptions?.map(
                ({
                  barcode,
                  barCodeNumber,
                  price,
                  refrigerated,
                  isNarcotics,
                  orderDetailId,
                }) => ({
                  barCodeNumber: barcode ?? barCodeNumber,
                  price,
                  refrigerated,
                  isNarcotics,
                  isDeleted: false,
                  orderDetailId,
                }),
              )
            : detail && !detail.length
            ? [
                {
                  barCodeNumber: '',
                  price: '',
                  refrigerated: false,
                  isNarcotics: false,
                  isDeleted: false,
                },
              ]
            : detail?.map(
                ({
                  barcode,
                  price,
                  isRefrigerated,
                  isNarcotics,
                  orderDetailId,
                }) => ({
                  barCodeNumber: barcode,
                  price,
                  refrigerated: isRefrigerated,
                  isNarcotics,
                  isDeleted: false,
                  orderDetailId,
                }),
              ),
        paymentMethod: 'NONE',
        fullName: get(newDeliveryData, 'customerName', '') || customerName,
        phoneNumber: get(newDeliveryData, 'customerPhone', '') || customerPhone,
        shippingAddress:
          get(newDeliveryData, 'customerAddress', '') || customerAddress,
        deliveryNote: get(newDeliveryData, 'deliveryNote', '') || orderNote,
        creditCard:
          get(newDeliveryData, 'customerCreditCard', '') ||
          customerCreditCard ||
          'NONE',
        cardHolderName: get(paymentDetails, 'cardholderName', ''),
      }
      if (order.orderId) reset(updatedForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, reset, selectedDelivery, paymentDetails])

  useEffect(() => {
    const {orderId} = order
    if (orderId) {
      const {payment, stripePaymentIntentId} = order
      if (stripePaymentIntentId && !payment) {
        getPayment({orderId, stripePaymentIntentId})
        return clearPayment
      }
    }
  }, [order, clearPayment, getPayment])

  useEffect(() => {
    if (latestTransactionSuccess) {
      handleModalOpen({
        modalContent: 'ScriptRunnerCardSuccessModal',
      })
      if (latestTransaction.transaction)
        setFetchedTransaction(latestTransaction.transaction)
    }
  }, [latestTransaction, latestTransactionSuccess])

  useEffect(() => {
    if (formSubmitted) {
      handleSavePrescription()
      setFormSubmitted(false)
    }
  }, [formSubmitted])

  const handlePriceChange = () => {
    setValue('cardHolderName', '')
    setValue('cardNumber', '')
    setValue('cardExpiry', '')
    setValue('cardCvc', '')
  }

  const handleSubmitPrescription = (form, successCb) => {
    const {stripePaymentIntentId} = getOrderFromDelivery(
      selectedDelivery,
      orderId,
    )
    const order = {
      ...form,
      stripePaymentIntentId,
    }
    addOrder({
      order,
      deliveryId,
      history,
      successCb,
    })
    handleModalClose()
  }

  const updatePrescription = (form, successCb) => {
    const cleanedForm = {
      ...form,
      prescriptions: form.prescriptions.map((prescription) => ({
        ...prescription,
        price: prescription.price
          ? prescription.price.replace(/[^0-9.]/g, '')
          : prescription.price,
      })),
    }
    const {prescriptions} = cleanedForm
    const newPrescriptions = prescriptions.map((eachPrep) =>
      eachPrep.isDeleted
        ? {orderDetailId: eachPrep.orderDetailId, isDeleted: eachPrep.isDeleted}
        : eachPrep,
    )

    const updatedOrder = {
      ...form,
      prescriptions: newPrescriptions,
      customerAddress: form.customerAddress || form.shippingAddress,
      shippingAddress: form.customerAddress || form.shippingAddress,
    }

    const currentFormValues = getValues()
    const currentShippingValues = {
      fullName: shippingFormFullName,
      phoneNumber: shippingFormPhoneNumber,
      address: shippingFormAddress,
      deliveryNote: shippingFormDeliveryNote,
      creditCard: shippingFormCreditCard,
    }

    updateOrder({
      orderId,
      updatedOrder,
      history,
      deliveryId,
      successCb: () => {
        if (successCb) successCb()
      },
      placeId: selectedPlaceId,
      destinationId: onfleetDestinationId,
      errorCb: () => {
        Object.keys(currentFormValues).forEach((key) => {
          if (key === 'prescriptions') {
            currentFormValues.prescriptions.forEach((prescription, index) => {
              setValue(`prescriptions.${index}`, prescription)
            })
          } else {
            setValue(key, currentFormValues[key])
          }
        })

        setShippingFormFullName(currentShippingValues.fullName)
        setShippingFormPhoneNumber(currentShippingValues.phoneNumber)
        setShippingFormAddress(currentShippingValues.address)
        setShippingFormDeliveryNote(currentShippingValues.deliveryNote)
        setShippingFormCreditCard(currentShippingValues.creditCard)
      },
    })
    handleModalClose()
  }

  const handleModalOpen = ({modalContent, isPurchaseModal}) => {
    setModal({
      isOpen: true,
      modalContent,
      isPurchaseModal,
    })
  }
  const handleModalPurchaseOpen = () => {
    updatePurchaseModalFlag({
      isModalOpen: true,
      orderId: parseInt(orderId),
      successCb: () =>
        handleModalOpen({
          modalContent: 'ScriptRunnerCardLoadingModal',
        }),
    })
  }

  const handleModalClose = (isPurchaseModal = false) => {
    setModal({
      isOpen: false,
      modalContent: '',
      isPurchaseModal: isPurchaseModal,
    })
  }

  const handleAddNewPrescription = () => {
    const prescriptionValues = {
      barCodeNumber: '',
      price: '',
      refrigerated: false,
      isDeleted: false,
    }

    append(prescriptionValues)
    setAutofocusIndex(fields.length)
  }

  const onScriptRunnerCardConfirm = () => {
    if (latestTransactionType === 'CHARGE') {
      updatePurchaseModalFlag({isModalOpen: false, orderId: null})
    }
    const orderReference = order.orderId || emptyOrderId
    getLatestTransaction({orderReference, latestTransactionType})
  }

  async function openScriptRunnerCardLoadingModal() {
    await handleModalPurchaseOpen()
  }

  const handleCancelPrescription = () => {
    handleModalOpen({
      modalContent: isEditableOrder
        ? 'CancelPrescriptionModal'
        : 'CancelPrescriptionDraftModal',
    })
  }

  const handleScriptRunnerCardSuccessModal = () => {
    handleModalClose()
    resetLatestTransactionSuccess()
    updateDeliverDataOnForm()
  }

  const handleAddressModalClose = () => {
    handleModalClose()
    clearAddresses()
  }

  const removeUnsavedPrescriptions = (position) => {
    remove(position)
  }
  const displayModal = (modalType, values) => {
    switch (modalType) {
      case 'CancelPrescriptionModal':
        return (
          <CancelPrescriptionModal
            handleClose={handleModalClose}
            redirectUrl={`/dashboard/delivery/${deliveryId}`}
            isScriptRunnerCardPayment={
              !FETCHED_AMOUNT_ZERO && (ORDERED_WITH_SR || SR_TOUCHED)
            }
          />
        )
      case 'CancelPrescriptionDraftModal':
        return (
          <CancelPrescriptionDraftModal
            handleClose={handleModalClose}
            redirectUrl={`/dashboard/delivery/${deliveryId}`}
            isScriptRunnerCardPayment={SR_TOUCHED}
            deleteOrder={deleteOrder}
            orderId={orderId}
          />
        )
      case 'ScriptRunnerCardLoadingModal':
        return (
          <ScriptRunnerCardLoadingModal
            handleClose={() => {
              handleModalClose(true)
            }}
            onClick={onScriptRunnerCardConfirm}
            orderId={orderId}
          />
        )
      case 'ScriptRunnerCardLoadingModalRefund':
        return (
          <ScriptRunnerCardLoadingModal
            handleClose={handleModalClose}
            onClick={onScriptRunnerCardConfirm}
            isCharge={latestTransactionType === TRANSACTION_TYPES.CHARGE}
          />
        )
      case 'ScriptRunnerCardSuccessModal':
        return (
          <ScriptRunnerCardSuccessModal
            handleDone={handleScriptRunnerCardSuccessModal}
            date={formatDate(latestTransaction?.transaction?.date)}
            total={
              latestTransaction?.transaction?.authorizationAmount || '$0.00'
            }
            isRefund={latestTransactionType === TRANSACTION_TYPES.REFUND}
          />
        )
      case 'ConfirmAddressModal':
        return (
          <ConfirmAddressModal
            handleClose={handleAddressModalClose}
            updateSelectedAddress={handleAddressSelection}
            setFormSubmitted={setFormSubmitted}
            destinations={destinations}
            updatePrescription={updatePrescription}
            getValues={getValues}
            orderId={orderId}
            handleSubmitPrescription={handleSubmitPrescription}
            setValue={setValue}
          />
        )
      default:
        break
    }
  }

  function onError(err) {
    console.log('Error on form submission: ', err)
  }

  function updateDeliverDataOnForm() {
    const {prescriptions} = getValues()
    updateDeliveryData({
      customerName: shippingFormFullName,
      customerAddress: shippingFormAddress,
      customerPhone: shippingFormPhoneNumber,
      prescriptions: prescriptions,
      deliveryNote: shippingFormDeliveryNote,
      customerCreditCard: shippingFormCreditCard,
      fetchedTransaction: fetchedTransaction,
    })
  }

  function handleScriptRunnerCardClick() {
    setLatestTransactionType(TRANSACTION_TYPES.CHARGE)
    updateDeliverDataOnForm()
    openScriptRunnerCardLoadingModal()
  }

  const handleSavePrescription = async () => {
    const {prescriptions} = getValues()
    const lastIndex = prescriptions.length - 1
    const lastPrescription = prescriptions[lastIndex]
    if (lastIndex > 0) {
      if (
        lastPrescription.barCodeNumber === '' &&
        lastPrescription.price === ''
      ) {
        await remove(lastIndex)
      }
    }
    updateDeliverDataOnForm()
    formRef.current.dispatchEvent(new Event('submit'))
  }

  const handleValidateAddress = async () => {
    await handleSubmit((validFormData) => {
      const currentFormValues = getValues()

      updateDeliveryData({
        customerName: currentFormValues.fullName,
        customerAddress: currentFormValues.shippingAddress,
        customerPhone: currentFormValues.phoneNumber,
        prescriptions: currentFormValues.prescriptions,
        deliveryNote: currentFormValues.deliveryNote,
        customerCreditCard: currentFormValues.creditCard,
        fetchedTransaction: fetchedTransaction,
      })

      clearAddresses()
      setHasValidationRun(true)

      const {prescriptions} = currentFormValues
      const newPrescriptions = prescriptions.map((prescription) => {
        if (prescription.isDeleted) {
          return {
            orderDetailId: prescription.orderDetailId,
            isDeleted: prescription.isDeleted,
          }
        }

        return {
          ...prescription,
          price: prescription.price
            ? prescription.price.replace(/[^0-9.]/g, '')
            : prescription.price,
        }
      })

      validateAddress({
        customerAddress: currentFormValues.shippingAddress,
        orderId,
        deliveryId,
        prescriptions: newPrescriptions,
        errorCb: () => {
          // Get the latest values from Redux
          setValue('fullName', currentFormValues.fullName)
          setValue('phoneNumber', currentFormValues.phoneNumber)
          setValue('shippingAddress', currentFormValues.shippingAddress)
          setValue('deliveryNote', currentFormValues.deliveryNote)
          setValue('creditCard', currentFormValues.creditCard)

          // Also update shipping form state
          setShippingFormFullName(currentFormValues.fullName)
          setShippingFormPhoneNumber(currentFormValues.phoneNumber)
          setShippingFormAddress(currentFormValues.shippingAddress)
          setShippingFormDeliveryNote(currentFormValues.deliveryNote)
          setShippingFormCreditCard(currentFormValues.creditCard)
        },
      })
    }, onError)()
  }

  const handleAddressSelection = (destinationId) => {
    const selected = destinations.find(
      (dest) => dest.googlePlaceId === destinationId,
    )

    if (selected) {
      const address = selected.address.description
      setSelectedPlaceId(destinationId)
      setShippingFormAddress(address)
      setValue('shippingAddress', address, {
        shouldValidate: true,
        shouldDirty: true,
      })
      if (selected.onfleetDestinationId) {
        setOnfleetDestinationId(selected.onfleetDestinationId)
      }
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(() => {}, onError)} ref={formRef}>
        <TopContainer className={classes.topContainer}>
          <BackButton click={handleCancelPrescription} />
          <Typography variant="h1" className={classes.header}>
            {isEditableOrder ? `Edit Order` : `New Order`}
          </Typography>
          {!isEditableOrder && (
            <CancelButton
              type="button"
              onClick={handleCancelPrescription}></CancelButton>
          )}
        </TopContainer>
        <BarcodeList>
          {fields.map((prescription, position) => {
            const shouldFocus = position === autofocusIndex
            const style = prescription.isDeleted ? {display: 'none'} : {}
            return (
              <li style={style} key={position}>
                <PrescriptionForm
                  key={prescription.id}
                  position={position}
                  autoFocus={shouldFocus}
                  prescription={prescription}
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  insert={insert}
                  remove={remove}
                  getValues={getValues}
                  removeUnsavedPrescriptions={removeUnsavedPrescriptions}
                  addNewPrescription={handleAddNewPrescription}
                  onPriceChange={handlePriceChange}
                />
              </li>
            )
          })}
        </BarcodeList>
        <AddPrescriptionButton
          id="add-new-prescription-button"
          onClick={handleAddNewPrescription}>
          Add New Prescription
        </AddPrescriptionButton>
        <ShippingForm
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          setShippingFormFullName={setShippingFormFullName}
          setShippingFormPhoneNumber={setShippingFormPhoneNumber}
          setShippingFormAddress={setShippingFormAddress}
          setShippingFormDeliveryNote={setShippingFormDeliveryNote}
          setShippingFormCreditCard={setShippingFormCreditCard}
          initialAddress={shippingFormAddress}
          setPlaceId={setSelectedPlaceId}
        />
        <Divider />
        <Typography
          variant="h1"
          style={{marginBottom: '8px'}}
          className={classes.header}>
          Payment
        </Typography>
        {/* NOTE: Do not delete - this will be used in the future for Credit Card payment} */}
        <Controller
          as={
            <RadioGroup
              // TODO: display for credit card functionality
              style={{display: 'none'}}
              row
              aria-label="payment method"
              type="string">
              <Radio id="none-radio-button" value="none" label="None" />
              <Radio
                id="credit-radio-button"
                value="credit"
                label="Credit Card"
              />
              <Radio id="cash-radio-button" value="cash" label="Cash" />
            </RadioGroup>
          }
          name="paymentMethod"
          control={control}
          defaultValue="cash"
        />

        <PaymentDescription>
          Use ScriptRunners prepaid card to process the payment on your POS
        </PaymentDescription>

        <Row>
          <ScriptRunnerCardButton
            id="script-runner-card-button"
            data-testid="sr-card-button"
            style={{margin: '0', lineHeight: 'normal'}}
            className={classes.button}
            onClick={handleScriptRunnerCardClick}>
            ScriptRunner Card
          </ScriptRunnerCardButton>

          {!isEmpty(fetchedTransaction) ? (
            <CardPaymentReg
              style={{
                justifyContent: fetchedTransaction.orderAmount
                  ? 'space-between'
                  : 'center',
              }}>
              <DateText>Charged On {fetchedTransaction.date}</DateText>
              <PaidAmountInput value={fetchedTransaction.orderAmount} />
            </CardPaymentReg>
          ) : (
            <CardPaymentReg
              style={{
                justifyContent:
                  order.latestChargeDate && ORDERED_WITH_SR
                    ? 'space-between'
                    : 'center',
              }}>
              {order.latestChargeDate && ORDERED_WITH_SR ? (
                <DateText> Charged on {order.latestChargeDate}</DateText>
              ) : (
                <></>
              )}
              <PaidAmountInput
                value={
                  order.paymentTotal && ORDERED_WITH_SR
                    ? `$${order.paymentTotal}`
                    : '$0.00'
                }
              />
            </CardPaymentReg>
          )}
        </Row>
        <ProceedButton
          id="save-prescription-button"
          className={classes.button}
          onClick={handleValidateAddress}>
          Proceed
        </ProceedButton>
      </Form>

      <Modal
        data-testid="card-modal"
        isOpen={modal.isOpen}
        handleClose={handleModalClose}
        isPurchaseModal={modal.isPurchaseModal}
        updatePurchaseModalFlag={updatePurchaseModalFlag}
        orderId={orderId}>
        {displayModal(modal.modalContent)}
      </Modal>
      <Loading open={loading} />
    </>
  )
}

const mapStateToProps = ({
  deliveries: {newDeliveryData = {}, selectedDelivery},
  payment: {
    details: paymentDetails,
    latestTransaction,
    latestTransactionSuccess,
  },
  app: {loading},
  address,
}) => {
  return {
    selectedDelivery,
    newDeliveryData,
    paymentDetails,
    loading,
    latestTransaction,
    latestTransactionSuccess,
    destinations: address.destinations,
    addressLoading: address.loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearPayment: (payload) => dispatch(clearPaymentMethod(payload)),
  getPayment: (payload) => dispatch(getPayment(payload)),
  addOrder: (payload) => dispatch(addOrder(payload)),
  setPaymentMethod: (payload) => dispatch(paymentMethodSuccess(payload)),
  getOrder: (orderId) => dispatch(getOrder({orderId})),
  updateOrder: (payload) => dispatch(updateOrder(payload)),
  getLatestTransaction: (payload) => dispatch(getLatestTransaction(payload)),
  startLoading: () => dispatch(startLoading()),
  stopLoading: () => dispatch(stopLoading()),
  updateDeliveryData: (payload) => dispatch(updateDeliveryData(payload)),
  deleteOrder: (orderId) => dispatch(deleteOrder({orderId})),
  resetLatestTransactionSuccess: () =>
    dispatch(resetLatestTransactionSuccess()),
  updatePurchaseModalFlag: (payload) =>
    dispatch(updatePurchaseModalFlag(payload)),
  validateAddress: (payload) => dispatch(validateAddress(payload)),
  clearAddresses: () => dispatch(clearAddresses()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPrescription)
